import { render, staticRenderFns } from "./reset.template.pug?vue&type=template&id=5d60dd3d&lang=pug&external"
import script from "./reset.script.js?vue&type=script&lang=js&external"
export * from "./reset.script.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports